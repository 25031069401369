import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Layout from 'components/Layout';
import SEO from 'components/Seo';
import Link from 'components/Link';

import 'locales/i18n';

const PrivacyPage = ({ location }) => {
  const { t, i18n } = useTranslation();

  return (
    <Layout location={location}>
      <SEO
        title={t('privacy.title')}
        lang={i18n.language || 'fr'}
        metaTitle={t('privacy.title')}
        metaDescription={t('privacy.description')}
      />

      <div className="row my-6">
        <div className="col-md-6 col-sm-8 mx-auto">
          <h1>{t('privacy.title')}</h1>
          <div className="general-conditions">
            <p>
              Dopamine Company SA respecte votre droit à la vie privée. La
              présente politique vise à identifier les types de données
              personnelles que l'application Hitch peut être amenée à collecter
              ainsi que la manière dont nous sommes susceptibles de les
              utiliser.
            </p>
            <h4 style={{ marginTop: '40px' }}>
              Quelles informations nous récoltons ?
            </h4>
            <p>
              <strong>Pour les utilisateurs:</strong>
            </p>
            <ol>
              <li>
                <strong>nom et prénom</strong> (pour identification du client et
                la création du compte sur Hitch app )
              </li>
              <li>
                <strong>Adresse privée</strong> (pour la facturation et
                réservation des séances)
              </li>
              <li>
                <strong>Adresse mail</strong> (les notifications de réservation
                et les communications interne avec la plateforme Hitch et la
                helpline)
              </li>
              <li>
                <strong>numéro de téléphone</strong> (pour l'identification à
                l'application et vérification par SMS)
              </li>
              <li>
                <strong>coordonnées bancaires</strong> (collectée uniquement par
                notre partenaire Stripe pour permettre aux utilisateurs de
                réserver et payer leurs prestations coaching avec leur coach)
              </li>
              <li>
                <Link className="text-underscore" to="/cgu">
                  Acceptation des conditions générales
                </Link>{' '}
                de la plateforme Hitch
              </li>
            </ol>
            <p>
              <strong>Pour les coachs</strong>
            </p>
            <p>
              Nos coachs sont rencontrés par les experts Hitch qui a leur tour
              prennent :
            </p>
            <ol>
              <li>
                <strong>Nom, prénom et pièce d’identité</strong> (pour
                identifier la personne et aussi créer sont compte sur la
                plateforme Hitch)
              </li>
              <li>
                <strong>adresse mail</strong> (pour envoyer des informations sur
                la platform Hitch et aussi pour la help line et mises à jour)
              </li>
              <li>
                <strong>Adresse privé</strong> (pour confirmer que les coachs
                vivent principalement en Suisse)
              </li>
              <li>
                <strong>telephone</strong> (pour l'identification à la
                plateforme et pour permettre un contact entre coachs et clients,
                ainsi que les experts Hitch)
              </li>
              <li>
                <strong>diplômes et photo du coach</strong> (ce qui nous permet
                de garantir une authenticité de la certification de chaque coach
                sélectionné par les experts Hitch et aussi le visuel sur son
                profile Hitch)
              </li>
              <li>
                Consentement d’utilisation et respect{' '}
                <Link className="text-underscore" to="/cgu">
                  des conditions générales
                </Link>{' '}
                sous forme de contrat papier entre le coach et Dopamine Company
                SA (Lausanne Suisse)
              </li>
              <li>
                <strong>Coordonnées bancaires</strong> (sont demandées par
                Stripe et non par Dopamine Company SA). Ces informations sont
                intégrées dans le système par le coach lui même et non par les
                experts Hitch.
              </li>
            </ol>

            <h4 style={{ marginTop: '40px' }}>
              Comment nous utilisons ces informations?
            </h4>
            <p>
              <strong>Pour les coachs</strong>
            </p>
            <p>
              Ces informations sont utilisés principalement sur notre plateforme
              Hitch que le coach rempli lui même. Ces informations peuvent bien
              entendu être changées ou modifiées par le coach lui même qui a
              accès à sont compte en Tout temps.
              <br />
              Les contrats ainsi que les diplômes des coachs sont répertoriés et
              archivés sous format papier chez Dopamine Company SA à Lausanne
              (Suisse)
            </p>
            <p>
              <strong>Pour les utilisateurs:</strong>
            </p>
            <p>
              Ces informations sont principalement et intégralement utilisées
              sur notre plateforme Hitch et nos utilisateurs peuvent à tout
              moment changer, modifier leurs informations.
              <br />
              Nom, prénom et numéro de téléphone sont visibles uniquement par
              les coachs.
            </p>
            <p>
              <strong>Qu’elles informations nous partageons?</strong>
            </p>
            <p>
              Ces informations sont strictement utilisées que sur la plateforme
              Hitch et aucunement partagé avec des tiers.
            </p>
            <p>
              <strong>Pour les coachs</strong>
            </p>
            <ul>
              <li>nom et prénom</li>
              <li>diplômes</li>
              <li>spécialités du coach</li>
              <li>numero de telephone</li>
              <li>photo du profile</li>
              <li>son agenda (disponibilités)</li>
              <li>lieux de travail</li>
              <li>ses tarifs</li>
              <li>langues parlées</li>
            </ul>
            <p>Ces informations sont visibles par les utilisateurs.</p>

            <p>
              <strong>Pour les utilisateurs:</strong>
            </p>
            <ul>
              <li>nom, prénom</li>
              <li>photo du profil</li>
              <li>numéro de téléphone</li>
            </ul>
            <p>Ces informations sont visibles par les coachs.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

PrivacyPage.propTypes = {
  location: PropTypes.object.isRequired,
};
PrivacyPage.defaultProps = {};

export default PrivacyPage;
